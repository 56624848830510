$color-black: #2d2d2d;
$color-white: #ffffff;
$color-dark: #9c8b58;
$color-dark-blue: #2c3e57;
$color-grey: #949494;
$color-light-grey: #dddddd;
$color-yellow: #fdf2c5;
$color-white-grey: #f8f8fc;
$color-green: #00ac3c;
$color-blue: #136dc7;
$color-red: #d14343;
$color-chat-grey: #f0f0f0;
$theme-scroll-color: white;
$theme-color-input: #19212f;
$theme-btn-color: #005b41;
$theme-color-Faqs: #19212f;
$side-nav-color: #1a212f;
$bg-color: #0550a6;

$theme-background-color: #fafafa;
$top-nav-color: #111322;
$text-color: #f75dbe;
// $top-nav-color: #867CF3;
$theme-footer-color: #1684e6;
