.post_container {
  .headers {
    gap: 20px;

    .title {
      font-weight: normal;
      font-size: 30px;
      font-family: "Gilroy-ExtraBold" !important;
      text-transform: capitalize;
      color: black;
      user-select: none;
    }
    .desc {
      font-size: 20px;
      color: black;
      user-select: none;
    }
  }

  .post-images {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    border-radius: 10px;
    width: 100%;
    overflow-x: hidden;
    cursor: pointer;

    img {
      flex-grow: 1;
      flex-basis: calc(33.33% - 10px);
      object-fit: cover;
      border-radius: 10px;
      min-width: 150px;
      max-height: 230px;
      // &:hover {
      //   background-color: #000;
      //   opacity: 0.8;
      //   transition: 0.5 all linear infinite;
      //   .icon {
      //     display: block;
      //   }
      // }
    }

    &.image-1 {
      flex-direction: column;
      img {
        min-width: 250px;
        height: 230px;
      }
    }

    &.image-2 {
      img {
        flex-basis: calc(50% - 10px);
        min-width: 200px;
      }
    }

    &.image-3 {
      flex-wrap: wrap;
      img:first-child {
        flex-basis: calc(50% - 10px);
        min-width: 250px;
      }

      img:nth-child(2),
      img:nth-child(3) {
        flex-basis: calc(50% - 10px);
        min-width: 200px;
      }
      // display: flex;
      // flex-wrap: nowrap;
      // height: 100%;
      // gap: 10px; // Gap between images

      // img:first-child {
      //   flex: 0 1 50%;  // First image takes 50% width
      //   height: 100%;   // Takes full height of the container
      //   max-width: calc(50% - 10px); // Minus the gap
      // }

      // img:nth-child(2),
      // img:nth-child(3) {
      //   flex: 0 1 50%;  // Second and third images take 50% width combined
      //   max-height: calc(50% - 5px);  // Split the remaining height between the two images
      // }

      // img:nth-child(2) {
      //   align-self: flex-start; // Align second image to the top
      // }

      // img:nth-child(3) {
      //   align-self: flex-end;   // Align third image to the bottom
      // }
    }

    &.image-4 {
      flex-wrap: wrap;

      img {
        flex-basis: calc(50% - 10px);
        min-width: 200px;
      }
    }

    &.image-more {
      flex-wrap: wrap;

      img {
        flex-basis: calc(33.33% - 10px);
        min-width: 150px;
      }
    }
    @media screen and (max-width: 768px) {
      img {
        flex-grow: 1;
        flex-basis: calc(33.33% - 10px);
        object-fit: cover;
        border-radius: 10px;
        // min-width: 150px;
        // height: 230px;
      }

      &.image-1 {
        flex-direction: column;
        img {
          width: 100%;
          height: 100px;
        }
      }

      &.image-2 {
        img {
          flex-basis: calc(50% - 10px);
          min-width: 100px;
          height: 100px;
        }
      }

      &.image-3 {
        flex-wrap: wrap;
        img:first-child {
          flex-basis: calc(50% - 10px);
          min-width: 150px;
          height: 100px;
        }

        img:nth-child(2),
        img:nth-child(3) {
          flex-basis: calc(50% - 10px);
          min-width: 100px;
          height: 100px;
        }
        // display: flex;
        // flex-wrap: nowrap;
        // height: 100%;
        // gap: 10px; // Gap between images

        // img:first-child {
        //   flex: 0 1 50%;  // First image takes 50% width
        //   height: 100%;   // Takes full height of the container
        //   max-width: calc(50% - 10px); // Minus the gap
        // }

        // img:nth-child(2),
        // img:nth-child(3) {
        //   flex: 0 1 50%;  // Second and third images take 50% width combined
        //   max-height: calc(50% - 5px);  // Split the remaining height between the two images
        // }

        // img:nth-child(2) {
        //   align-self: flex-start; // Align second image to the top
        // }

        // img:nth-child(3) {
        //   align-self: flex-end;   // Align third image to the bottom
        // }
      }

      &.image-4 {
        flex-wrap: wrap;

        img {
          flex-basis: calc(50% - 10px);
          min-width: 100px;
          height: 100px;
        }
      }

      &.image-more {
        flex-wrap: wrap;

        img {
          flex-basis: calc(33.33% - 10px);
          min-width: 50px;
          height: 100px;
        }
      }
    }
  }
}
