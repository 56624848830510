.application-feature {
  background-image: url("../images/Home/ApplicationFeature/bg_image.png"), url("../images/Home/ApplicationFeature/bg_image_2.png");
  background-repeat: no-repeat;
  background-size: cover;

  .application_feature_component {
    padding-inline: 20px;
    padding-block: 50px;

    .headers {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .title {
        font-weight: bold;
        font-size: 50px;
        font-family: "Gilroy-ExtraBold" !important;
        text-transform: capitalize;
        color: black;
        user-select: none;
      }

      .title_2 {
        font-size: 48px;
        text-transform: capitalize;
        color: black;
        user-select: none;
      }
    }

    .mobile_section {
      .image_section {
        img {
          height: 600px;
          object-fit: contain;
        }
      }

      .description {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 40px;

        h4 {
          font-family: "Gilroy-ExtraBold" !important;
          text-transform: capitalize;
          font-size: 18px;
        }

        .details {
          text-transform: capitalize;
          font-size: 16px;
          color: #9d9d9d;
        }
      }


    }
  }
}