.terms-page {
  .page-heading {
    width: 650px;
    font-weight: bold;
    font-size: 55px;
    font-family: "Gilroy-ExtraBold" !important;
    text-transform: capitalize;
    color: #282828;
    user-select: none;
    line-height: 100px;

    @media screen and (max-width: 768px) {
      width: 100% !important;
      font-size: 50px !important;
      line-height: 70px !important;
      height: auto;
    }

    @media screen and (max-width: 400px) {
      width: 100% !important;
      font-size: 30px !important;
      line-height: 40px !important;
      line-height: 50px !important;
      height: auto;
    }
  }

  .terms-layout {
    .list-select {
      .column-list {
        ul {
          border-right: 11px solid #f4f4f4;

          li {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 46px;
            /* or 232% */
            color: #000000;
          }

          .active {
            color: #0a64ca;
            font-weight: bold;
          }
        }
      }

      .column-content {
        max-height: 900px;
        overflow: auto;
        overflow-x: hidden;

        scroll-behavior: smooth;
        @media screen and (max-width: 768px) {
          max-height: 100% !important;
        }

        &::-webkit-scrollbar {
          width: 0;
          /* Hide scrollbar */
        }

        .content {
          .main-heading {
            font-family: "Gilroy-Bold";
            font-style: normal;
            font-weight: bold;
            font-size: 46px;
            line-height: 56px;
            /* identical to box height, or 66% */
            text-transform: capitalize;
            color: #000000;
            @media screen and (max-width: 768px) {
              font-size: 36px !important;
              line-height: 40px !important;
            }
            span {
            }
          }

          .sub-heading-para {
            h3 {
              font-family: "Gilroy-SemiBold";
              font-style: normal;
              font-weight: 400;
              font-size: 25px;
              line-height: 35px;
              /* identical to box height, or 149% */
              color: #000000;
            }
          }

          p {
            font-family: "Gilroy-Regular";
            font-style: normal;
            font-weight: 400;
            font-size: 19px;
            line-height: 37px;
            /* or 196% */
            color: #000000;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
