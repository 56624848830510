@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: bolder;
  src: url("../fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-BoldItalic";
  font-style: italic;
  font-weight: bolder;
  src: url("../fonts/Gilroy/Gilroy-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-ExtraBold";
  font-style: normal;
  src: url("../fonts/Gilroy/Gilroy-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-ExtraBoldItalic";
  font-style: italic;
  src: url("../fonts/Gilroy/Gilroy-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBoldItalic";
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/Gilroy/Gilroy-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Gilroy/Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-LightItalic";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/Gilroy/Gilroy-LightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-MediumItalic";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/Gilroy/Gilroy-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBold";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Gilroy/Gilroy-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBoldItalic";
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/Gilroy/Gilroy-SemiBoldItalic.ttf") format("truetype");
}

@import "./_Color.scss";
@import "./_Default.scss";
@import "./_MediaQuery.scss";
@import "./_ThemeModal.scss";
@import "./_HomePage.scss";
@import "./_footer.scss";
@import "./_topNavBar.scss";
@import "./_Privacy.scss";
@import "./_ContactHeader.scss";
@import "./_ApplicationFeature.scss";
@import "./_contactUs.scss";
@import "./_terms.scss";
@import "./_gallery.scss";
