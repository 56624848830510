.contact-us {
  label {
    font-weight: bold;
  }

  input {
    font-size: 14px;
    background: white;
  }

  textarea {
    font-size: 14px;
  }

  button {
    background: #0550a6;
    color: white;
    border-radius: 26px;

    button:hover {
      background: #0550a6;
      color: white;
    }
  }

  .contact-us-btn {
    background: #1684e6;
    color: white;
    border-radius: 26px;

    .contact-us-btn:hover {
      background: #0550a6;
      color: white;
    }
  }
}
