.contact-us-header {
  background-color: #0550a6;
  color: white;
  padding: 12px;

  .contact-list {
    display: flex;
    align-items: center;
    gap: 25px;
    @media screen and (max-width: 767px) {
      text-align: center;
      justify-content: center;
    }
    @media screen and (max-width: 479px) {
      flex-direction: column;
      gap: 0px;
      margin-bottom: 10px;
    }
  }

  .contact-list .email_phone {
    display: flex;
    align-items: center;
    justify-items: center;
    gap: 9px;
    font-size: 14px;
    font-weight: 500;
    padding-top: 4px;
  }

  .social_icons {
    display: flex;
    align-items: center;
    justify-content: end;
    @media screen and (max-width: 767px) {
      text-align: center;
      justify-content: center;
    }
    img {
      margin-left: 25px;
      width: 21.52px;
      height: 21.52px;
    }
  }
}
