@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: bolder;
  src: url("../fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-BoldItalic";
  font-style: italic;
  font-weight: bolder;
  src: url("../fonts/Gilroy/Gilroy-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-ExtraBold";
  font-style: normal;
  src: url("../fonts/Gilroy/Gilroy-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-ExtraBoldItalic";
  font-style: italic;
  src: url("../fonts/Gilroy/Gilroy-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-SemiBoldItalic";
  font-style: italic;
  font-weight: normal;
  src: url("../fonts/Gilroy/Gilroy-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Light";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/Gilroy/Gilroy-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-LightItalic";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/Gilroy/Gilroy-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-MediumItalic";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/Gilroy/Gilroy-MediumItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-SemiBold";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Gilroy/Gilroy-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-SemiBoldItalic";
  font-style: italic;
  font-weight: bold;
  src: url("../fonts/Gilroy/Gilroy-SemiBoldItalic.ttf") format("truetype");
}
body {
  margin: 0;
  padding: 0;
  font-family: "Lato";
  background-color: #fafafa;
  color: black;
  display: flex;
  flex-direction: column;
  font-family: "Lato";
  min-height: 100%;
  margin-left: 0px;
  margin-right: 0px;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: #136dc7;
  border-radius: 100px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  padding: 0;
}
ul li {
  list-style: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #19212f inset !important;
  -webkit-text-fill-color: #ffffff;
}

.cursor {
  cursor: pointer;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.lh-0-ls-5 {
  line-height: unset !important;
  letter-spacing: 5px;
}

.color-dark {
  color: #9c8b58;
}

.color-black {
  color: #2d2d2d;
}

.color-blue {
  color: #2c3e57;
}

.color-grey {
  color: #949494;
}

.color-lightblue {
  color: #136dc7;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.go-back {
  width: 100%;
  text-align: start;
}

.line-podcast {
  /* Vector 199 */
  opacity: 0.1;
  border: 1px solid #ffffff;
}

.vertical-line {
  /* Line 1 */
  background: #ffffff;
  opacity: 0.4;
  border: 1.94758px solid #dddddd;
}
@media screen and (max-width: 820px) {
  .vertical-line {
    display: none;
  }
}

.custom-back {
  background-color: #1a212f;
  min-height: 963px;
}

.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label::after {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:not(:placeholder-shown) ~ label::after {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:focus ~ label::after {
  border: none;
  background-color: transparent !important;
}

.form-floating > .form-control:focus ~ label {
  border: none !important;
  background-color: transparent !important;
}

.form-floating > .form-control:focus {
  border: none !important;
  background-color: #19212f;
}

.vertical-line-video {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
}
@media screen and (max-width: 820px) {
  .vertical-line-video {
    display: none;
  }
}

.default-align {
  align-items: start !important;
}

.btn-theme-default {
  background: #ffffff;
  border-radius: 57px;
  /* contact Us */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  text-transform: capitalize;
  color: #24252d;
}

.span-kansensus {
  color: #867cf3;
}

.form-floating label {
  font-size: 14px;
  color: #949494;
}
.form-floating.small-input .form-control {
  height: 43px;
}
.form-floating.small-input label {
  top: -7px;
}
.form-floating .form-control {
  border-radius: 8px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.form-floating .form-control:not(:-moz-placeholder-shown) {
  padding-top: 0;
  padding-bottom: 0;
}
.form-floating .form-control:not(:placeholder-shown) {
  padding-top: 0;
  padding-bottom: 0;
}
.form-floating .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 1;
  top: -3px;
  left: 10px;
  background: transparent;
  width: auto;
  height: auto;
  padding: 0 5px;
  color: black;
}
.form-floating .form-control:not(:placeholder-shown) ~ label {
  opacity: 1;
  top: -3px;
  left: 10px;
  background: transparent;
  width: auto;
  height: auto;
  padding: 0 5px;
  color: black;
}
.form-floating .form-control:focus ~ label {
  opacity: 1;
  top: -3px;
  left: 10px;
  width: auto;
  height: auto;
  padding: 0 5px;
  color: black;
  background: transparent;
}

.form-floating > .form-control {
  padding: 17px;
  min-height: 0px;
}

.form-floating > .form-control:focus ~ label::after {
  border: none;
  background-color: transparent;
}

.form-control {
  border: 1px solid #dddddd;
  outline: none;
}
.form-control:hover, .form-control:focus {
  box-shadow: none;
  border: 1px solid #ffffff;
  outline: none;
}

.input:-internal-autofill-selected {
  background-color: #19212f !important;
}

.password-input {
  position: relative;
}
.password-input .eye-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 16px;
  cursor: pointer;
  color: #949494;
}

.horizontol-line {
  width: 1177px;
  height: 0px;
  border: 1px solid #333333;
}

.email-button-mobile {
  display: none; /* Default to hidden */
}

@media only screen and (max-width: 750px) {
  .email-button-mobile {
    display: inline-block; /* or any other display property you prefer */
  }
}
.children {
  min-height: 340px;
}

@media screen and (max-width: 1200px) {
  .create-post-feature-component .content h1 {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 380px) {
  .create-post-feature-component .content h1 {
    font-size: 40px !important;
    line-height: 50px !important;
  }
}
@media screen and (max-width: 1200px) {
  .create-post-feature-component .content h1 span {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 380px) {
  .create-post-feature-component .content h1 span {
    font-size: 40px !important;
    line-height: 50px !important;
  }
}
@media screen and (max-width: 550px) {
  .create-post-feature-component .content .store_image {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
}
@media screen and (max-width: 550px) {
  .create-post-feature-component .content .store_image img {
    width: 250px !important;
    margin-top: 20px;
  }
}
.create-post-feature-component .add-image-column {
  text-align: center;
}
@media screen and (max-width: 991px) {
  .create-post-feature-component .add-image-column .add-image {
    width: 100% !important;
    height: 100% !important;
  }
}

@media screen and (max-width: 580px) {
  .ready-to-get-started-component {
    width: 450px !important;
  }
}
@media screen and (max-width: 450px) {
  .ready-to-get-started-component {
    width: 80% !important;
    padding: 20px !important;
  }
}
@media screen and (max-width: 450px) {
  .ready-to-get-started-component .col-center {
    padding: 20px !important;
  }
}
.ready-to-get-started-component .content {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .ready-to-get-started-component .content h1 {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 450px) {
  .ready-to-get-started-component .content h1 {
    font-size: 33px !important;
    line-height: 43px !important;
  }
}
@media screen and (max-width: 450px) {
  .ready-to-get-started-component .content p {
    margin-top: 30px !important;
    line-height: 28px;
  }
}
@media screen and (max-width: 450px) {
  .ready-to-get-started-component .store_image {
    width: 100% !important;
    margin-top: 40px !important;
  }
}
@media screen and (max-width: 450px) {
  .ready-to-get-started-component .store_image img {
    width: 100% !important;
  }
}

@media screen and (max-width: 1200px) {
  .user-flow-info .home-slider-componenet .top-text h1 {
    font-size: 50px !important;
  }
}
@media screen and (max-width: 1200px) {
  .user-flow-info .home-slider-componenet .top-text h1 span {
    font-size: 50px !important;
  }
}

@media screen and (max-width: 991px) {
  .navbar-toggler {
    background-color: #0550a6;
  }
}

@media screen and (max-width: 580px) {
  .subscribe-input-email {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 580px) {
  .subscribe-input-email .subscribe-container {
    width: 80% !important;
  }
}
@media screen and (max-width: 450px) {
  .subscribe-input-email .subscribe-container .subscribe-button {
    width: 100% !important;
  }
}

.theme-popup {
  /* Settings */
  border-radius: 30px;
  padding: 30px;
}
.theme-popup .modal-body {
  padding: 40px;
}
.theme-popup .modal-content {
  background-color: #232D3F;
  border-radius: 30px;
}
.theme-popup .modal-content h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #FFFFFF;
  opacity: 0.96;
}
.theme-popup .modal-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
}
.theme-popup .modal-content button {
  width: 212px;
  height: 48px;
}
.theme-popup .modal-content .btn-cancel {
  background-color: #ffffff;
  border: none;
  color: #fafafa;
  font-weight: 700;
}
.theme-popup .modal-content .btn-okay {
  background-color: #005b41;
  border: none;
}

/* VideoModal.css */
.video-theme-popup .modal-content {
  background-color: transparent;
  /* Set background color of modal content */
  border: none;
  box-shadow: none;
  padding: 0px !important;
}
.video-theme-popup .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.video-theme-popup .modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 80%;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.video-theme-popup .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  /* Group 34770 */
  width: 25.51px;
  height: 25.51px;
}
.video-theme-popup iframe {
  width: 100%;
  height: 371px;
  border: none;
  border-radius: 8px;
}

.home-page .add-header {
  background-color: #111322;
}
.home-page .add-header .add-header-component {
  background-image: url("../images/Home/TopHeader/bg_image.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  padding-bottom: 50px;
}
.home-page .add-header .add-header-component .head-data .speak-up {
  width: 566px;
  font-weight: bold;
  font-size: 60px;
  line-height: 80px;
  font-family: "Gilroy-ExtraBold" !important;
  text-transform: capitalize;
  color: #282828;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
@media screen and (max-width: 768px) {
  .home-page .add-header .add-header-component .head-data .speak-up {
    width: 100% !important;
    font-size: 50px !important;
    line-height: 60px !important;
    height: auto;
  }
}
@media screen and (max-width: 369px) {
  .home-page .add-header .add-header-component .head-data .speak-up {
    width: 100% !important;
    font-size: 30px !important;
    line-height: 40px !important;
    height: auto;
  }
}
.home-page .add-header .add-header-component .store_image {
  display: flex;
  margin-top: 50px;
  gap: 30px;
  align-items: center;
}
@media screen and (max-width: 550px) {
  .home-page .add-header .add-header-component .store_image {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
}
.home-page .add-header .add-header-component .store_image img {
  width: 250px;
}
.home-page .add-header .headers {
  gap: 20px;
}
.home-page .add-header .headers .title {
  font-weight: normal;
  font-size: 30px;
  font-family: "Gilroy-ExtraBold" !important;
  text-transform: capitalize;
  color: black;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.home-page .add-header .headers .desc {
  font-size: 20px;
  color: black;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.home-page .social-media-home-page .social-media-component .join-us-detail .text h1 {
  width: 611px;
  height: 240px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: #323232;
}
@media screen and (max-width: 1200px) {
  .home-page .social-media-home-page .social-media-component .join-us-detail .text h1 {
    font-size: 35px;
    list-style: 45px;
    width: 511px;
  }
}
@media screen and (max-width: 1200px) {
  .home-page .social-media-home-page .social-media-component .join-us-detail .text h1 {
    font-size: 35px;
    list-style: 45px;
    width: 411px;
  }
}
@media screen and (max-width: 750px) {
  .home-page .social-media-home-page .social-media-component .join-us-detail .text h1 {
    width: 100%;
    font-size: 50px;
    line-height: 60px;
    height: auto;
    margin-bottom: 30px;
  }
}
.home-page .social-media-home-page .social-media-component .join-us-detail .text h1 span {
  color: #867cf2 !important;
}
.home-page .social-media-home-page .social-media-component .join-us-detail .text p {
  width: 565px;
  height: 59px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 29px;
  /* or 172% */
  text-transform: capitalize;
  color: #413d45;
}
@media screen and (max-width: 750px) {
  .home-page .social-media-home-page .social-media-component .join-us-detail .text p {
    width: 100%;
    height: auto;
  }
}
.home-page .social-media-home-page .social-media-component .join-us-detail .os-type {
  display: flex;
}
@media screen and (max-width: 600px) {
  .home-page .social-media-home-page .social-media-component .join-us-detail .os-type {
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .home-page .social-media-home-page .social-media-component .join-us-detail .os-type img {
    width: 100%;
    margin-bottom: 30px;
  }
}
.home-page .social-media-home-page .social-media-component .join-us-detail .os-type .apple {
  margin-right: 20px;
}
@media screen and (max-width: 750px) {
  .home-page .social-media-home-page .social-media-component .types {
    margin-top: 30px;
  }
}
.home-page .social-media-home-page .social-media-component .types .os-type-image {
  flex-direction: column;
}
.home-page .features-home-page {
  background-color: #f2f2f2;
}
.home-page .features-home-page .features-component .feature-image {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 58px 0px;
  background-image: url("../images/Home/Choosesec/bg.png");
  background-repeat: no-repeat;
  text-align: center;
}
@media screen and (max-width: 991px) {
  .home-page .features-home-page .features-component .feature-image {
    text-align: center;
  }
}
.home-page .features-home-page .features-component .feature-image img {
  width: 399.85px;
  height: 615px;
}
@media screen and (max-width: 400px) {
  .home-page .features-home-page .features-component .feature-image img {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 360px) {
  .home-page .features-home-page .features-component .feature-image img {
    width: 100%;
    height: auto;
  }
}
.home-page .features-home-page .features-component .feature-details .content {
  /* src/FeatureList.css */
}
.home-page .features-home-page .features-component .feature-details .content h5 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */
  text-transform: capitalize;
  color: #867cf2;
}
.home-page .features-home-page .features-component .feature-details .content h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: #323232;
}
.home-page .features-home-page .features-component .feature-details .content h1 span {
  color: #867cf2;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list {
  position: relative;
  padding-left: 30px;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item input[type=radio] {
  position: absolute;
  border: #6e2bff;
  top: 8px;
  width: 12px;
  height: 12px;
  accent-color: #6e2bff;
  opacity: 0;
  cursor: pointer;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item input[type=radio] + label::before {
  content: "";
  position: absolute;
  left: -23.5px;
  top: 7px;
  width: 12px;
  height: 12px;
  border: 2px solid #6e2bff;
  border-radius: 50%;
  background: #fff;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item input[type=radio] + label::after {
  content: "";
  position: absolute;
  left: -23.5px;
  top: 5px;
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  display: none;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item input[type=radio]:checked + label::before {
  background-color: #6e2bff;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item input[type=radio]:checked + label::after {
  border: 2px solid blue;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */
  text-transform: capitalize;
  color: #867cf2;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list .feature-item p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  color: #808080;
}
.home-page .features-home-page .features-component .feature-details .content .feature-list::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 7.5px;
  bottom: 0;
  width: 2px;
  /* Adjust the width based on your image */
  background-image: url("../images/Home/Features/Verticalline.png");
  background-repeat: no-repeat;
}
.home-page .choose-kansensus-home-page .choose-kansensus-component {
  background-image: url("../images/Home/Choosesec/bg.png");
  background-position: right;
  background-repeat: no-repeat;
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content {
  width: 550px !important;
}
@media screen and (max-width: 991px) {
  .home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content {
    width: 100% !important;
    height: auto;
  }
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content h5 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */
  text-transform: capitalize;
  color: #97a1ff;
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-transform: capitalize;
  color: #323232;
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content h1 span {
  color: #867cf3;
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content .para {
  width: 500px;
}
@media screen and (max-width: 991px) {
  .home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content .para {
    width: 100% !important;
    height: auto;
  }
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-details .content .para p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  color: #808080;
}
@media screen and (max-width: 991px) {
  .home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-image {
    text-align: center;
  }
}
.home-page .choose-kansensus-home-page .choose-kansensus-component .choose-kansensus-image img {
  width: 100%;
  height: auto;
}
.home-page .our-users-home-page .our-users-component {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 1400px;
  /* Adjust based on your design */
  margin: 0 auto;
}
.home-page .our-users-home-page .our-users-component .content {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-page .our-users-home-page .our-users-component .content h1 {
  width: 808px;
  height: 51px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  line-height: 114.2%;
  text-align: center;
  color: #2a2222;
}
@media screen and (max-width: 750px) {
  .home-page .our-users-home-page .our-users-component .content h1 {
    width: 100%;
    height: auto;
  }
}
.home-page .our-users-home-page .our-users-component .content h3 {
  width: 649px;
  height: 42px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 134.2%;
  text-align: center;
  text-transform: capitalize;
  color: #484848;
}
@media screen and (max-width: 750px) {
  .home-page .our-users-home-page .our-users-component .content h3 {
    width: 100%;
    height: auto;
    height: auto;
  }
}
.home-page .our-users-home-page .our-users-component .button-btn {
  border: none !important;
  background: transparent !important;
  margin: 10px 20px;
}
.home-page .our-users-home-page .our-users-component .slick-prev:before,
.home-page .our-users-home-page .our-users-component .slick-next:before {
  color: #000;
}
.home-page .close-home-page .closer-component .content {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.home-page .close-home-page .closer-component .content h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: #323232;
}
.home-page .close-home-page .closer-component .content h3 {
  width: 817px;
  height: 56px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  text-align: center;
  color: #808080;
}
@media screen and (max-width: 800px) {
  .home-page .close-home-page .closer-component .content h3 {
    width: 100%;
    font-size: 16px;
    height: auto;
  }
}
.home-page .close-home-page .closer-component .add-banner {
  text-align: center;
}
.home-page .close-home-page .closer-component .add-banner img {
  width: 100%;
  height: auto;
}
.home-page .joinwaitlist-home-page .joinwaitlist-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 1279px;
  height: 302px;
  background-color: #7880cb;
  border-radius: 30px;
  background-image: url("../images/Home/joincomponent/bg.png");
  /* Your background image */
  background-position: right center;
  /* Position the image to the right center */
  background-repeat: no-repeat;
  /* Ensure the image doesn't repeat */
  background-size: contain;
  /* Adjust the size of the image to fit the element */
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component {
    width: 100%;
    font-size: 44px;
    line-height: 54px;
  }
}
.home-page .joinwaitlist-home-page .joinwaitlist-component h1 {
  width: 689px;
  height: 85.16px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 77px;
  text-transform: capitalize;
  color: #fafafa;
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component h1 {
    width: 100%;
    font-size: 44px;
    line-height: 54px;
  }
}
@media screen and (max-width: 400px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component h1 {
    width: 100%;
    height: auto;
    font-size: 24px;
    line-height: 34px;
  }
}
.home-page .joinwaitlist-home-page .joinwaitlist-component h5 {
  width: 574px;
  height: 34.28px;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  text-transform: capitalize;
  color: #fafafa;
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component h5 {
    font-size: 16px;
    line-height: 26px;
  }
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component h5 {
    width: 100%;
    line-height: 54px;
  }
}
@media screen and (max-width: 400px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component h5 {
    margin-top: 10px !important;
    width: 100%;
    font-size: 14px;
    line-height: 34px;
  }
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email {
  width: 580px;
  height: 66.36px;
  border-radius: 50px;
  position: relative;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component .email {
    width: 450px;
  }
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component .email {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component .email {
    width: 300px;
  }
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper {
  position: relative;
  width: 100%;
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper .name {
  /* Rectangle 3 */
  box-sizing: border-box;
  width: 100%;
  height: 66.36px;
  background: white;
  border: none;
  font-family: "Lato";
  border-radius: 50px;
  color: black;
  font-size: 14px;
  font-weight: 600;
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper button {
  width: 174px;
  height: 49px;
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper button {
    width: 100px;
  }
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper .email-button {
  gap: 10px;
  position: absolute;
  right: 12px;
  top: 32px;
  transform: translateY(-50%);
  background: #97a1ff;
  border-radius: 50px;
  border: none;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper .email-button {
    font-size: 13px;
  }
}
@media screen and (max-width: 750px) {
  .home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper .email-button {
    display: none;
  }
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email .input-wrapper .email-button:hover {
  background-color: #0056b3;
  /* Darker shade for hover effect */
}
.home-page .joinwaitlist-home-page .joinwaitlist-component .email-button-mobile {
  margin-top: 10px;
  gap: 10px;
  transform: translateY(-50%);
  background: #97a1ff;
  border-radius: 50px;
  border: none;
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.home-page .create-post-application {
  background-image: url("../images/Home/createpost/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 50px 0px;
}
.home-page .create-post-application .create-post-feature-component .content h1 {
  font-family: "Gilroy-ExtraBold" !important;
  font-style: normal;
  font-weight: bold;
  font-size: 55px;
  line-height: 65px;
  /* or 113% */
  color: #000000;
}
.home-page .create-post-application .create-post-feature-component .content h1 span {
  font-weight: 300;
  font-size: 55px;
  line-height: 65px;
  /* or 113% */
  display: flex;
  align-items: center;
  color: #313131;
}
.home-page .create-post-application .create-post-feature-component .content p {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  /* or 168% */
  color: #000000;
  opacity: 0.5;
}
.home-page .create-post-application .create-post-feature-component .content .store_image img {
  width: 250px;
  height: 250px;
}
.home-page .create-post-application .create-post-feature-component .add-image-column img {
  width: 531.89px;
  height: 618.13px;
}
.home-page .my-profile-image {
  background-image: url("../images/Home/Myprofile/bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 50px 0px;
}

@media screen and (max-width: 991px) {
  .home-page .add-header .add-header-component .head-data {
    margin-bottom: 20px;
  }
  .application-feature .application_feature_component .headers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .application-feature .application_feature_component .headers .title {
    font-weight: bold;
    font-size: 40px !important;
    font-family: "Gilroy-ExtraBold" !important;
    text-transform: capitalize;
    color: black;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
  .application-feature .application_feature_component .headers .title_2 {
    font-size: 30px !important;
    text-transform: capitalize;
    color: black;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
}
.user-flow-info .home-slider-componenet .top-text {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-flow-info .home-slider-componenet .top-text h1 {
  font-family: "Gilroy-Black";
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  line-height: 79px;
  /* identical to box height, or 113% */
  color: #000000;
}
.user-flow-info .home-slider-componenet .top-text h1 span {
  font-family: "Gilroy-Light";
  font-style: normal;
  font-weight: 300;
  font-size: 70px;
  line-height: 79px;
  /* identical to box height, or 113% */
  color: #000000;
}
.user-flow-info .home-slider-componenet .slider-component *,
.user-flow-info .home-slider-componenet .slider-component *::before,
.user-flow-info .home-slider-componenet .slider-component *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}
.user-flow-info .home-slider-componenet .slider-component :root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}
.user-flow-info .home-slider-componenet .slider-component html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
@media (min-width: 1440px) {
  .user-flow-info .home-slider-componenet .slider-component html {
    zoom: 1.5;
  }
}
@media (min-width: 2560px) {
  .user-flow-info .home-slider-componenet .slider-component html {
    zoom: 1.7;
  }
}
@media (min-width: 3860px) {
  .user-flow-info .home-slider-componenet .slider-component html {
    zoom: 2.5;
  }
}
.user-flow-info .home-slider-componenet .slider-component ::-webkit-scrollbar {
  width: 1.3rem;
}
.user-flow-info .home-slider-componenet .slider-component ::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.user-flow-info .home-slider-componenet .slider-component ::-webkit-scrollbar-thumb:hover {
  background: #222224;
}
.user-flow-info .home-slider-componenet .slider-component ::-webkit-scrollbar-track {
  background: #f9f9f9;
}
.user-flow-info .home-slider-componenet .slider-component body {
  font-size: 1.6rem;
  background: var(--bg);
}
.user-flow-info .home-slider-componenet .slider-component .container {
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}
.user-flow-info .home-slider-componenet .slider-component .heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}
.user-flow-info .home-slider-componenet .slider-component .swiper_container {
  height: 55rem;
  padding: 2rem 0;
  position: relative;
}
.user-flow-info .home-slider-componenet .slider-component .swiper-slide {
  width: 37rem;
  height: 42rem;
  position: relative;
}
@media (max-width: 500px) {
  .user-flow-info .home-slider-componenet .slider-component .swiper_container {
    height: 47rem;
  }
  .user-flow-info .home-slider-componenet .slider-component .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .user-flow-info .home-slider-componenet .slider-component .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
}
.user-flow-info .home-slider-componenet .slider-component .swiper-slide img {
  width: 35rem;
  height: 735px;
  border-radius: 2rem;
  -o-object-fit: contain;
     object-fit: contain;
}
.user-flow-info .home-slider-componenet .slider-component .swiper-slide-shadow-left,
.user-flow-info .home-slider-componenet .slider-component .swiper-slide-shadow-right {
  display: none;
}
.user-flow-info .home-slider-componenet .slider-component .slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-flow-info .home-slider-componenet .slider-component .slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}
@media (max-width: 990px) {
  .user-flow-info .home-slider-componenet .slider-component .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}
@media (max-width: 450px) {
  .user-flow-info .home-slider-componenet .slider-component .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}
@media (max-width: 990px) {
  .user-flow-info .home-slider-componenet .slider-component .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}
@media (max-width: 450px) {
  .user-flow-info .home-slider-componenet .slider-component .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}
.user-flow-info .home-slider-componenet .slider-component .slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}
.user-flow-info .home-slider-componenet .slider-component .slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}
.user-flow-info .home-slider-componenet .slider-component .slider-controler .slider-arrow::after {
  content: "";
}
.user-flow-info .home-slider-componenet .slider-component .swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}
.user-flow-info .home-slider-componenet .slider-component .swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}
.user-flow-info .home-slider-componenet .slider-component .swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}

.ready-to-get-started .ready-to-get-started-component .col-center {
  background-color: #f4f4f4;
  border-radius: 100px 0px 100px 0px;
  padding: 100px;
}
@media screen and (max-width: 1200px) {
  .ready-to-get-started .ready-to-get-started-component .col-center {
    height: 600px;
  }
}
.ready-to-get-started .ready-to-get-started-component .col-center .content h1 {
  font-family: "Gilroy-ExtraBold";
  font-style: normal;
  font-weight: 600;
  font-size: 63.4654px;
  line-height: 65px;
  /* or 102% */
  text-transform: capitalize;
  color: #000000;
}
.ready-to-get-started .ready-to-get-started-component .col-center .content p {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 22.918px;
  line-height: 38px;
  /* or 164% */
  text-transform: capitalize;
  color: #000000;
}
.ready-to-get-started .ready-to-get-started-component .col-center .content .store_image {
  background-color: white;
  width: 315.75px;
  border-radius: 50px;
  padding: 20px;
}
.ready-to-get-started .ready-to-get-started-component .col-center .content .store_image img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 221.48px;
}
.ready-to-get-started .ready-to-get-started-component .blue-bg {
  background-color: #1e9dfb;
  border-radius: 0px 100px 0px 100px;
  height: 576.11px;
}
@media screen and (max-width: 1250px) {
  .ready-to-get-started .ready-to-get-started-component .blue-bg {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1100px) {
  .ready-to-get-started .ready-to-get-started-component .blue-bg {
    width: 400px;
    height: 500px;
  }
}
.ready-to-get-started .ready-to-get-started-component .blue-bg img {
  height: 631.69px;
  width: 618.03px;
  margin-top: -90px;
  margin-left: -180px;
}
@media screen and (max-width: 1250px) {
  .ready-to-get-started .ready-to-get-started-component .blue-bg img {
    width: 600px;
    height: 600px;
    margin-top: -50px;
    margin-left: -70px;
  }
}
@media screen and (max-width: 1100px) {
  .ready-to-get-started .ready-to-get-started-component .blue-bg img {
    width: 500px;
    height: 500px;
    margin-top: -50px;
    margin-left: -70px;
  }
}

.page-fotter {
  background-color: #1684e6;
}
.page-fotter .content {
  padding: 50px 0px;
}
@media screen and (max-width: 991px) {
  .page-fotter .content {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
.page-fotter .content .logo-sec .footer-follow-us-links {
  align-items: center;
  justify-content: end;
}
@media screen and (max-width: 991px) {
  .page-fotter .content .logo-sec .footer-follow-us-links {
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
  }
}
.page-fotter .content .logo-sec .footer-follow-us-links .follow-us-text {
  margin-right: 10px;
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 20.4324px;
  line-height: 38px;
  /* identical to box height, or 185% */
  display: flex;
  align-items: center;
  color: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.page-fotter .content .logo-sec .footer-follow-us-links .social-links .icon-images {
  margin-left: 20px;
}
@media screen and (max-width: 991px) {
  .page-fotter .content .page-name-sec .page-links {
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 450px) {
  .page-fotter .content .page-name-sec .page-links {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
  }
}
.page-fotter .content .page-name-sec .page-links .page-names {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  /* identical to box height, or 202% */
  display: flex;
  align-items: center;
  color: #ffffff;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 20px;
}
.page-fotter .content .page-name-sec .subscribe-container {
  display: flex;
  align-items: center;
  background-color: #ffffff; /* Background color of the input container */
  border-radius: 25px; /* To make the edges rounded */
  overflow: hidden; /* To keep the rounded corners */
  box-shadow: none;
  width: 631.23px;
  height: 77.79px;
  padding: 0px 14px;
}
@media screen and (max-width: 1290px) {
  .page-fotter .content .page-name-sec .subscribe-container {
    width: 560px;
  }
}
@media screen and (max-width: 1200px) {
  .page-fotter .content .page-name-sec .subscribe-container {
    width: 500px;
  }
}
@media screen and (max-width: 1080px) {
  .page-fotter .content .page-name-sec .subscribe-container {
    width: 100%;
  }
}
@media screen and (max-width: 580px) {
  .page-fotter .content .page-name-sec .subscribe-container {
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .page-fotter .content .page-name-sec .subscribe-container {
    flex-direction: column;
  }
}
.page-fotter .content .page-name-sec .subscribe-input {
  flex: 1; /* Take up the remaining space */
  padding: 10px 20px; /* Padding inside the input */
  border: none; /* Remove the border */
  border-radius: 25px 0 0 25px; /* Round the left corners */
  font-size: 16px;
  outline: none; /* Remove the outline on focus */
}
.page-fotter .content .page-name-sec .subscribe-button {
  padding: 10px 20px; /* Padding inside the button */
  background-color: #d7edfd; /* Button background color */
  border: none; /* Remove the border */
  color: #000000; /* Button text color */
  border-radius: 25px; /* Round the right corners */
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease; /* Transition effect for hover */
  width: 237px;
  height: 68px;
}
@media screen and (max-width: 450px) {
  .page-fotter .content .page-name-sec .subscribe-button {
    width: 200px;
    height: 50px;
  }
}
.page-fotter .content .page-name-sec .subscribe-button:hover {
  background-color: #bcd4f6; /* Darker shade on hover */
}
.page-fotter .content .copyright-row {
  border-top: 1px solid #d4ecfc;
}
@media screen and (max-width: 991px) {
  .page-fotter .content .copyright-row {
    padding-top: 20px;
  }
}
.page-fotter .content .copyright-row .copy-right-text {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */
  color: #ffffff;
}
@media screen and (max-width: 570px) {
  .page-fotter .content .copyright-row .copy-right-text {
    font-size: 14px;
  }
}
@media screen and (max-width: 550px) {
  .page-fotter .content .copyright-row .store_image {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
}
@media screen and (max-width: 550px) {
  .page-fotter .content .copyright-row .store_image img {
    width: 250px !important;
    margin-top: 20px;
  }
}
.page-fotter .content .copyright-row .store_image .image-store img {
  width: 255.18px;
  height: 75.67px;
}
@media screen and (max-width: 1080px) {
  .page-fotter .content .copyright-row .store_image .image-store img {
    width: 100%;
  }
}

.top-nav-bar img {
  width: 90px;
  -o-object-fit: contain;
     object-fit: contain;
}
.top-nav-bar .btn-theme-default {
  background: #0A64CA;
  color: white;
  border-radius: 57px;
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 24px;
  border: none;
  text-transform: capitalize;
}
.top-nav-bar .nav-link {
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 100%;
  color: #000;
}
.top-nav-bar a {
  font-family: "Gilroy" !important;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 100%;
  color: #000;
}
.top-nav-bar .btn-contact-us {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 148px;
  height: 44px;
  margin-top: 12px;
  margin-left: 16px;
}
.top-nav-bar .navbar-expand .navbar-nav {
  margin: 20px 22px;
}
.top-nav-bar .navbar .nav-link.active {
  border-bottom: 2px solid #0A64CA;
  font-family: "Gilroy";
  padding-bottom: 10px;
  color: #0A64CA;
}
.top-nav-bar .navbar .active {
  border-bottom: 2px solid #0A64CA;
  font-family: "Gilroy";
  padding-bottom: 10px;
  color: #0A64CA;
  font-weight: bold !important;
}

@media screen and (max-width: 370px) {
  .top-nav-bar .navbar-brand {
    width: 50%;
  }
}
.terms-and-condition h1 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: #323232;
}
.terms-and-condition h2 {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  text-transform: capitalize;
  color: #867cf2 !important;
}
.terms-and-condition p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 29px;
  /* or 172% */
  text-transform: capitalize;
  color: #413d45;
}

.contact-us-header {
  background-color: #0550a6;
  color: white;
  padding: 12px;
}
.contact-us-header .contact-list {
  display: flex;
  align-items: center;
  gap: 25px;
}
@media screen and (max-width: 767px) {
  .contact-us-header .contact-list {
    text-align: center;
    justify-content: center;
  }
}
@media screen and (max-width: 479px) {
  .contact-us-header .contact-list {
    flex-direction: column;
    gap: 0px;
    margin-bottom: 10px;
  }
}
.contact-us-header .contact-list .email_phone {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 9px;
  font-size: 14px;
  font-weight: 500;
  padding-top: 4px;
}
.contact-us-header .social_icons {
  display: flex;
  align-items: center;
  justify-content: end;
}
@media screen and (max-width: 767px) {
  .contact-us-header .social_icons {
    text-align: center;
    justify-content: center;
  }
}
.contact-us-header .social_icons img {
  margin-left: 25px;
  width: 21.52px;
  height: 21.52px;
}

.application-feature {
  background-image: url("../images/Home/ApplicationFeature/bg_image.png"), url("../images/Home/ApplicationFeature/bg_image_2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.application-feature .application_feature_component {
  padding-inline: 20px;
  padding-block: 50px;
}
.application-feature .application_feature_component .headers {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.application-feature .application_feature_component .headers .title {
  font-weight: bold;
  font-size: 50px;
  font-family: "Gilroy-ExtraBold" !important;
  text-transform: capitalize;
  color: black;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.application-feature .application_feature_component .headers .title_2 {
  font-size: 48px;
  text-transform: capitalize;
  color: black;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.application-feature .application_feature_component .mobile_section .image_section img {
  height: 600px;
  -o-object-fit: contain;
     object-fit: contain;
}
.application-feature .application_feature_component .mobile_section .description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}
.application-feature .application_feature_component .mobile_section .description h4 {
  font-family: "Gilroy-ExtraBold" !important;
  text-transform: capitalize;
  font-size: 18px;
}
.application-feature .application_feature_component .mobile_section .description .details {
  text-transform: capitalize;
  font-size: 16px;
  color: #9d9d9d;
}

.contact-us label {
  font-weight: bold;
}
.contact-us input {
  font-size: 14px;
  background: white;
}
.contact-us textarea {
  font-size: 14px;
}
.contact-us button {
  background: #0550a6;
  color: white;
  border-radius: 26px;
}
.contact-us button button:hover {
  background: #0550a6;
  color: white;
}
.contact-us .contact-us-btn {
  background: #1684e6;
  color: white;
  border-radius: 26px;
}
.contact-us .contact-us-btn .contact-us-btn:hover {
  background: #0550a6;
  color: white;
}

.terms-page .page-heading {
  width: 650px;
  font-weight: bold;
  font-size: 55px;
  font-family: "Gilroy-ExtraBold" !important;
  text-transform: capitalize;
  color: #282828;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  line-height: 100px;
}
@media screen and (max-width: 768px) {
  .terms-page .page-heading {
    width: 100% !important;
    font-size: 50px !important;
    line-height: 70px !important;
    height: auto;
  }
}
@media screen and (max-width: 400px) {
  .terms-page .page-heading {
    width: 100% !important;
    font-size: 30px !important;
    line-height: 40px !important;
    line-height: 50px !important;
    height: auto;
  }
}
.terms-page .terms-layout .list-select .column-list ul {
  border-right: 11px solid #f4f4f4;
}
.terms-page .terms-layout .list-select .column-list ul li {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 46px;
  /* or 232% */
  color: #000000;
}
.terms-page .terms-layout .list-select .column-list ul .active {
  color: #0a64ca;
  font-weight: bold;
}
.terms-page .terms-layout .list-select .column-content {
  max-height: 900px;
  overflow: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
@media screen and (max-width: 768px) {
  .terms-page .terms-layout .list-select .column-content {
    max-height: 100% !important;
  }
}
.terms-page .terms-layout .list-select .column-content::-webkit-scrollbar {
  width: 0;
  /* Hide scrollbar */
}
.terms-page .terms-layout .list-select .column-content .content .main-heading {
  font-family: "Gilroy-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 56px;
  /* identical to box height, or 66% */
  text-transform: capitalize;
  color: #000000;
}
@media screen and (max-width: 768px) {
  .terms-page .terms-layout .list-select .column-content .content .main-heading {
    font-size: 36px !important;
    line-height: 40px !important;
  }
}
.terms-page .terms-layout .list-select .column-content .content .sub-heading-para h3 {
  font-family: "Gilroy-SemiBold";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
  /* identical to box height, or 149% */
  color: #000000;
}
.terms-page .terms-layout .list-select .column-content .content p {
  font-family: "Gilroy-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 37px;
  /* or 196% */
  color: #000000;
  opacity: 0.5;
}

.post_container .headers {
  gap: 20px;
}
.post_container .headers .title {
  font-weight: normal;
  font-size: 30px;
  font-family: "Gilroy-ExtraBold" !important;
  text-transform: capitalize;
  color: black;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.post_container .headers .desc {
  font-size: 20px;
  color: black;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.post_container .post-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  border-radius: 10px;
  width: 100%;
  overflow-x: hidden;
  cursor: pointer;
}
.post_container .post-images img {
  flex-grow: 1;
  flex-basis: calc(33.33% - 10px);
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
  min-width: 150px;
  max-height: 230px;
}
.post_container .post-images.image-1 {
  flex-direction: column;
}
.post_container .post-images.image-1 img {
  min-width: 250px;
  height: 230px;
}
.post_container .post-images.image-2 img {
  flex-basis: calc(50% - 10px);
  min-width: 200px;
}
.post_container .post-images.image-3 {
  flex-wrap: wrap;
}
.post_container .post-images.image-3 img:first-child {
  flex-basis: calc(50% - 10px);
  min-width: 250px;
}
.post_container .post-images.image-3 img:nth-child(2),
.post_container .post-images.image-3 img:nth-child(3) {
  flex-basis: calc(50% - 10px);
  min-width: 200px;
}
.post_container .post-images.image-4 {
  flex-wrap: wrap;
}
.post_container .post-images.image-4 img {
  flex-basis: calc(50% - 10px);
  min-width: 200px;
}
.post_container .post-images.image-more {
  flex-wrap: wrap;
}
.post_container .post-images.image-more img {
  flex-basis: calc(33.33% - 10px);
  min-width: 150px;
}
@media screen and (max-width: 768px) {
  .post_container .post-images img {
    flex-grow: 1;
    flex-basis: calc(33.33% - 10px);
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 10px;
  }
  .post_container .post-images.image-1 {
    flex-direction: column;
  }
  .post_container .post-images.image-1 img {
    width: 100%;
    height: 100px;
  }
  .post_container .post-images.image-2 img {
    flex-basis: calc(50% - 10px);
    min-width: 100px;
    height: 100px;
  }
  .post_container .post-images.image-3 {
    flex-wrap: wrap;
  }
  .post_container .post-images.image-3 img:first-child {
    flex-basis: calc(50% - 10px);
    min-width: 150px;
    height: 100px;
  }
  .post_container .post-images.image-3 img:nth-child(2),
  .post_container .post-images.image-3 img:nth-child(3) {
    flex-basis: calc(50% - 10px);
    min-width: 100px;
    height: 100px;
  }
  .post_container .post-images.image-4 {
    flex-wrap: wrap;
  }
  .post_container .post-images.image-4 img {
    flex-basis: calc(50% - 10px);
    min-width: 100px;
    height: 100px;
  }
  .post_container .post-images.image-more {
    flex-wrap: wrap;
  }
  .post_container .post-images.image-more img {
    flex-basis: calc(33.33% - 10px);
    min-width: 50px;
    height: 100px;
  }
}/*# sourceMappingURL=style.css.map */