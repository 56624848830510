.page-fotter {
  background-color: $theme-footer-color;

  .content {
    @media screen and (max-width: 991px) {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    padding: 50px 0px;
    .logo-sec {
      .brand-logo {
      }

      .footer-follow-us-links {
        align-items: center;
        justify-content: end;
        // margin-top: 60px;
        @media screen and (max-width: 991px) {
          flex-direction: column;
          justify-content: center;
          margin-top: 20px;
        }

        .follow-us-text {
          margin-right: 10px;
          font-family: "Gilroy-Medium";
          font-style: normal;
          font-weight: 400;
          font-size: 20.4324px;
          line-height: 38px;
          /* identical to box height, or 185% */
          display: flex;
          align-items: center;
          color: #ffffff;
          /* Inside auto layout */
          flex: none;
          order: 0;
          flex-grow: 0;
        }
        .social-links {
          .icon-images {
            margin-left: 20px;
          }
        }
      }
    }
    .page-name-sec {
      .page-links {
        @media screen and (max-width: 991px) {
          justify-content: center;
          align-items: center;
          text-align: center;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 450px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          gap: 20px;
        }

        .page-names {
          font-family: "Gilroy-Medium";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 36px;
          /* identical to box height, or 202% */
          display: flex;
          align-items: center;
          color: #ffffff;
          /* Inside auto layout */
          flex: none;
          order: 0;
          flex-grow: 0;
          margin-right: 20px;
        }
      }
      .subscribe-container {
        display: flex;
        align-items: center;
        background-color: #ffffff; /* Background color of the input container */
        border-radius: 25px; /* To make the edges rounded */
        overflow: hidden; /* To keep the rounded corners */
        box-shadow: none;
        width: 631.23px;
        height: 77.79px;
        padding: 0px 14px;
        @media screen and (max-width: 1290px) {
          width: 560px;
        }
        @media screen and (max-width: 1200px) {
          width: 500px;
        }
        @media screen and (max-width: 1080px) {
          width: 100%;
        }
        @media screen and (max-width: 580px) {
          width: 100%;
        }
        @media screen and (max-width: 450px) {
          flex-direction: column;
        }

      }

      .subscribe-input {
        flex: 1; /* Take up the remaining space */
        padding: 10px 20px; /* Padding inside the input */
        border: none; /* Remove the border */
        border-radius: 25px 0 0 25px; /* Round the left corners */
        font-size: 16px;
        outline: none; /* Remove the outline on focus */
      }

      .subscribe-button {
        padding: 10px 20px; /* Padding inside the button */
        background-color: #d7edfd; /* Button background color */
        border: none; /* Remove the border */
        color: #000000; /* Button text color */
        border-radius: 25px; /* Round the right corners */
        cursor: pointer;
        font-size: 16px;
        transition: background-color 0.3s ease; /* Transition effect for hover */
        width: 237px;
        height: 68px;
        @media screen and (max-width: 450px) {
          width: 200px;
          height: 50px;
        }
      }

      .subscribe-button:hover {
        background-color: #bcd4f6; /* Darker shade on hover */
      }
    }
    .copyright-row {
      @media screen and (max-width: 991px) {
        padding-top: 20px;
      }
      // margin-top: 10px;
      border-top: 1px solid #d4ecfc;

      .copy-right-text {
        font-family: "Gilroy-Medium";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        /* identical to box height, or 100% */
        color: #ffffff;
        @media screen and (max-width: 570px) {
          font-size: 14px;
        }
      }
      .store_image {
        @media screen and (max-width: 550px) {
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
        }
        img {
          @media screen and (max-width: 550px) {
            width: 250px !important;
            margin-top: 20px;
          }
        }
        .image-store {
          img {
            width: 255.18px;
            height: 75.67px;
            @media screen and (max-width: 1080px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
