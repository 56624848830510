.home-page {
  .add-header {
    background-color: $top-nav-color;

    .add-header-component {
      background-image: url("../images/Home/TopHeader/bg_image.png");
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
      width: 100%;
      padding-bottom: 50px;

      .head-data {
        .speak-up {
          width: 566px;
          // height: 260px;
          font-weight: bold;
          font-size: 60px;
          line-height: 80px;
          font-family: "Gilroy-ExtraBold" !important;
          text-transform: capitalize;
          color: #282828;
          user-select: none;
          @media screen and (max-width: 768px) {
            width: 100% !important;
            font-size: 50px !important;
            line-height: 60px !important;
            height: auto;
          }
          @media screen and (max-width: 369px) {
            width: 100% !important;
            font-size: 30px !important;
            line-height: 40px !important;
            height: auto;
          }
        }
      }

      .store_image {
        display: flex;
        margin-top: 50px;
        gap: 30px;
        align-items: center;
        @media screen and (max-width: 550px) {
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
        }
        img {
          width: 250px;
        }
      }
    }
    .headers {
      gap: 20px;
  
      .title {
        font-weight: normal;
        font-size: 30px;
        font-family: "Gilroy-ExtraBold" !important;
        text-transform: capitalize;
        color: black;
        user-select: none;
      }
      .desc {
        font-size: 20px;
        color: black;
        user-select: none;
      }
    }
  }

  .social-media-home-page {
    .social-media-component {
      .join-us-detail {
        .text {
          h1 {
            width: 611px;
            height: 240px;
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 60px;
            text-transform: capitalize;
            color: #323232;

            @media screen and (max-width: 1200px) {
              font-size: 35px;
              list-style: 45px;
              width: 511px;
            }

            @media screen and (max-width: 1200px) {
              font-size: 35px;
              list-style: 45px;
              width: 411px;
            }

            @media screen and (max-width: 750px) {
              width: 100%;
              font-size: 50px;
              line-height: 60px;
              height: auto;
              margin-bottom: 30px;
            }

            span {
              color: #867cf2 !important;
            }
          }

          p {
            width: 565px;
            height: 59px;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 29px;
            /* or 172% */
            text-transform: capitalize;
            color: #413d45;

            @media screen and (max-width: 750px) {
              width: 100%;
              height: auto;
            }
          }
        }

        .os-type {
          display: flex;

          @media screen and (max-width: 600px) {
            flex-direction: column;
          }

          img {
            @media screen and (max-width: 600px) {
              width: 100%;
              margin-bottom: 30px;
            }
          }

          .apple {
            margin-right: 20px;
          }
        }
      }

      .types {
        @media screen and (max-width: 750px) {
          margin-top: 30px;
        }

        .os-type-image {
          flex-direction: column;
        }
      }
    }
  }

  .features-home-page {
    background-color: #f2f2f2;

    .features-component {
      // padding: 50px 0px;

      .feature-image {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 58px 0px;
        background-image: url("../images/Home/Choosesec/bg.png");
        background-repeat: no-repeat;
        text-align: center;

        @media screen and (max-width: 991px) {
          text-align: center;
        }

        img {
          width: 399.85px;
          height: 615px;

          // @media screen and (max-width: 991px) {
          //   width: 499.85px;
          //   height: 715px;
          // }

          // @media screen and (max-width: 991px) {
          //   width: 399.85px;
          //   height: 715px;
          // }

          @media screen and (max-width: 400px) {
            width: 100%;
            height: auto;
          }

          @media screen and (max-width: 360px) {
            width: 100%;
            height: auto;
          }
        }
      }

      .feature-details {
        .content {
          h5 {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            /* identical to box height, or 127% */
            text-transform: capitalize;
            color: #867cf2;
          }

          h1 {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 60px;
            text-transform: capitalize;
            color: #323232;

            span {
              color: #867cf2;
            }
          }

          /* src/FeatureList.css */

          .feature-list {
            position: relative;
            padding-left: 30px;

            .feature-item {
              display: flex;
              align-items: flex-start;
              position: relative;

              input[type="radio"] {
                position: absolute;
                border: #6e2bff;
                top: 8px;
                width: 12px;
                height: 12px;
                accent-color: #6e2bff;
                opacity: 0; // Hide the default radio button
                cursor: pointer;
              }

              input[type="radio"] + label {
                &::before {
                  content: "";
                  position: absolute;
                  left: -23.5px;
                  top: 7px;
                  width: 12px;
                  height: 12px;
                  border: 2px solid #6e2bff; // Border color
                  border-radius: 50%;
                  background: #fff;
                }

                &::after {
                  content: "";
                  position: absolute;
                  left: -23.5px;
                  top: 5px;
                  width: 12px;
                  height: 12px;
                  background: #fff; // Inner circle color
                  border-radius: 50%;
                  display: none;
                }
              }

              input[type="radio"]:checked + label {
                &::before {
                  background-color: #6e2bff; // Background color when checked
                }

                &::after {
                  border: 2px solid blue;
                }
              }

              label {
                // margin-left: 30px;
                // cursor: pointer;
              }

              h3 {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                line-height: 28px;
                /* identical to box height, or 127% */
                text-transform: capitalize;
                color: #867cf2;
              }

              p {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                /* or 156% */
                color: #808080;
              }

              // input[type="radio"]:checked+label h3 {
              //     // color: #6e2bff;
              //     /* Active state color */
              // }
            }

            &::before {
              content: "";
              position: absolute;
              left: 10px;
              top: 7.5px;
              bottom: 0;
              width: 2px;
              /* Adjust the width based on your image */
              background-image: url("../images/Home/Features/Verticalline.png");
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }

  .choose-kansensus-home-page {
    .choose-kansensus-component {
      background-image: url("../images/Home/Choosesec/bg.png");
      background-position: right;
      background-repeat: no-repeat;

      .choose-kansensus-details {
        .content {
          width: 550px !important;

          @media screen and (max-width: 991px) {
            width: 100% !important;
            height: auto;
          }

          h5 {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 28px;
            /* identical to box height, or 127% */
            text-transform: capitalize;
            color: #97a1ff;
          }

          h1 {
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 50px;
            text-transform: capitalize;
            color: #323232;

            span {
              color: #867cf3;
            }
          }

          .para {
            width: 500px;

            @media screen and (max-width: 991px) {
              width: 100% !important;
              height: auto;
            }

            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 28px;
              /* or 156% */
              color: #808080;
            }
          }
        }
      }

      .choose-kansensus-image {
        @media screen and (max-width: 991px) {
          text-align: center;
        }

        img {
          width: 100%;
          height: auto;

          // @media screen and (max-width: 1290px) {
          //   width: 400px;
          // }

          // @media screen and (max-width: 1100px) {
          //   width: 350px;
          //   height: 600px;
          // }

          // @media screen and (max-width: 991px) {
          //   width: 459.85px;
          //   height: 715px;
          // }

          // @media screen and (max-width: 991px) {
          //   width: 100%;
          //   height: auto;
          // }
        }
      }
    }
  }

  .our-users-home-page {
    .our-users-component {
      position: relative;
      overflow: hidden;
      width: 100%;
      max-width: 1400px;
      /* Adjust based on your design */
      margin: 0 auto;

      .content {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          width: 808px;
          height: 51px;
          font-family: "Gilroy";
          font-style: normal;
          font-weight: 700;
          font-size: 45px;
          line-height: 114.2%;
          text-align: center;
          color: #2a2222;

          @media screen and (max-width: 750px) {
            width: 100%;
            height: auto;
          }
        }

        h3 {
          width: 649px;
          height: 42px;
          font-family: "Gilroy";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 134.2%;
          text-align: center;
          text-transform: capitalize;
          color: #484848;

          @media screen and (max-width: 750px) {
            width: 100%;
            height: auto;
            height: auto;
          }
        }
      }

      // .card-details {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   transition: transform 0.5s ease;

      //   .card-our-user {
      //     flex: 0 0 336.6px;
      //     box-sizing: border-box;
      //     margin: 0 1px;
      //     padding: 10px 18px;
      //     border: none;
      //     width: 336.6px;
      //     height: 383.4px;
      //     background: #f5f2ed;
      //     opacity: 0.4;
      //     border-radius: 10.8px;
      //     transform: scale(0.8);
      //     transition: transform 0.5s ease, opacity 0.5s ease;

      //     &.active-card {
      //       margin-top: 45px;
      //       opacity: 1;
      //       // transform: scale(1);
      //     }

      //     svg {
      //       width: 67.5px;
      //       height: 72px;
      //     }

      //     .comment {
      //       width: 260.46px;
      //       height: 220.6px;
      //       font-family: "Gilroy";
      //       font-style: normal;
      //       font-weight: 600;
      //       font-size: 23.4px;
      //       line-height: 32px;
      //       letter-spacing: -0.63px;
      //       color: #413d45;
      //     }

      //     .name {
      //       max-width: 260px;
      //       height: 17px;
      //       font-family: "Gilroy";
      //       font-style: normal;
      //       font-weight: 500;
      //       font-size: 14px;
      //       line-height: 16px;
      //       letter-spacing: -0.126px;
      //       color: #67646a;
      //     }

      //     .detail {
      //       max-width: 200px;
      //       height: 15px;
      //       font-family: "Gilroy";
      //       font-style: normal;
      //       font-weight: 500;
      //       font-size: 14px;
      //       line-height: 14px;
      //       letter-spacing: -0.108px;
      //       color: #413d45;
      //     }
      //   }
      // }

      // .center .slick-slide {
      //   opacity: 0.5;
      //   transition: opacity 0.3s ease;
      //   div {
      //     opacity: 10;
      //     margin-top: 20px;
      //   }
      // }

      // .center .slick-center {
      //   opacity: 10;
      //   margin-top: 25px;
      // }

      // .slick-slide {
      //   width: 336px !important;
      // }
      // .slick-slide div {
      //   padding: 20px;
      //   font-size: 36px;
      //   height: 383.4px;
      //   margin: 0 10px;

      //   width: 336.6px !important;
      //   background: #f5f2ed;
      //   opacity: 0.4;
      //   border-radius: 10.8px;

      //   svg {
      //     width: 67.5px;
      //     height: 72px;
      //   }
      //   .comment {
      //     width: 260.46px;
      //     height: 210.6px;
      //     font-family: "Gilroy";
      //     font-style: normal;
      //     font-weight: 600;
      //     font-size: 23.4px;
      //     line-height: 32px;
      //     letter-spacing: -0.63px;
      //     color: #413d45;
      //   }
      //   .name {
      //     max-width: 260px;
      //     height: 17px;
      //     font-family: "Gilroy";
      //     font-style: normal;
      //     font-weight: 500;
      //     font-size: 14px;
      //     line-height: 16px;
      //     letter-spacing: -0.126px;
      //     color: #67646a;
      //   }

      //   .detail {
      //     max-width: 250px;
      //     height: 15px;
      //     font-family: "Gilroy";
      //     font-style: normal;
      //     font-weight: 500;
      //     font-size: 14px;
      //     line-height: 14px;
      //     letter-spacing: -0.108px;
      //     color: #413d45;
      //   }
      // }

      // .slick-prev:before,
      // .slick-next:before {
      //   color: red;
      // }

      // .slider-container {
      //   width: 100%;
      //   margin: auto;
      //   padding: 40px 118px;

      //   // svg {
      //   //   width: 67.5px;
      //   //   height: 72px;
      //   // }

      //   // .comment {
      //   //   width: 260.46px;
      //   //   height: 220.6px;
      //   //   font-family: "Gilroy";
      //   //   font-style: normal;
      //   //   font-weight: 600;
      //   //   font-size: 23.4px;
      //   //   line-height: 32px;
      //   //   letter-spacing: -0.63px;
      //   //   color: #413d45;
      //   // }

      //   // .name {
      //   //   max-width: 260px;
      //   //   height: 17px;
      //   //   font-family: "Gilroy";
      //   //   font-style: normal;
      //   //   font-weight: 500;
      //   //   font-size: 14px;
      //   //   line-height: 16px;
      //   //   letter-spacing: -0.126px;
      //   //   color: #67646a;
      //   // }

      //   // .detail {
      //   //   max-width: 200px;
      //   //   height: 15px;
      //   //   font-family: "Gilroy";
      //   //   font-style: normal;
      //   //   font-weight: 500;
      //   //   font-size: 14px;
      //   //   line-height: 14px;
      //   //   letter-spacing: -0.108px;
      //   //   color: #413d45;
      //   // }
      // }

      .button-btn {
        border: none !important;
        background: transparent !important;
        margin: 10px 20px;
      }

      // .slider-container {
      //   width: 100%;
      //   margin: 0px;
      //   padding: 40px 0;
      // }

      // .slick-prev {
      //   border: none;
      //   background: transparent;
      //   // left: 500px;
      //   // top: 475px;
      //   // background-image: url("../images/Home/ouruser/Back.png");
      // }

      // .slick-next {
      //   border: none;
      //   background: transparent;

      //   // right: 500px;
      //   // top: 475px;
      //   // background-image: url("../images/Home/ouruser/Forward.png");
      // }

      // .center .slick-slide {
      //   opacity: 0.5;
      //   transition: opacity 0.3s ease;
      // }

      // .center .slick-center {
      //   opacity: 1;
      //   margin-top: 28px;
      // }

      // .slide {
      //   display: flex !important;
      //   justify-content: center;
      //   align-items: center;
      // }

      // .testimonial {
      //   background: #f9f9f9;
      //   padding: 20px;
      //   border-radius: 10px;
      //   max-width: 300px;
      //   margin: auto;
      //   height: 383.4px;
      //   background: #f5f2ed;
      //   border-radius: 10.8px;

      //   svg {
      //     width: 67.5px;
      //     height: 72px;
      //   }

      //   .comment {
      //     width: 260.46px;
      //     height: 220.6px;
      //     font-family: "Gilroy";
      //     font-style: normal;
      //     font-weight: 600;
      //     font-size: 23.4px;
      //     line-height: 32px;
      //     letter-spacing: -0.63px;
      //     color: #413d45;
      //   }

      //   .name {
      //     max-width: 260px;
      //     height: 17px;
      //     font-family: "Gilroy";
      //     font-style: normal;
      //     font-weight: 500;
      //     font-size: 14px;
      //     line-height: 16px;
      //     letter-spacing: -0.126px;
      //     color: #67646a;
      //   }

      //   .detail {
      //     max-width: 200px;
      //     height: 15px;
      //     font-family: "Gilroy";
      //     font-style: normal;
      //     font-weight: 500;
      //     font-size: 14px;
      //     line-height: 14px;
      //     letter-spacing: -0.108px;
      //     color: #413d45;
      //   }
      // }

      // .testimonial p {
      //   font-size: 1rem;
      //   line-height: 1.5;
      //   margin: 10px 0;
      // }

      // .testimonial h4 {
      //   margin-top: 15px;
      //   font-size: 1.2rem;
      //   font-weight: bold;
      // }

      .slick-prev:before,
      .slick-next:before {
        color: #000;
      }
    }
  }

  .close-home-page {
    .closer-component {
      .content {
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
          font-family: "Gilroy";
          font-style: normal;
          font-weight: 700;
          font-size: 50px;
          line-height: 60px;
          text-transform: capitalize;
          color: #323232;
        }

        h3 {
          width: 817px;
          height: 56px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          /* or 156% */
          text-align: center;
          color: #808080;

          @media screen and (max-width: 800px) {
            width: 100%;
            font-size: 16px;
            height: auto;
          }
        }
      }

      .add-banner {
        text-align: center;

        img {
          width: 100%;
          height: auto;

          // @media screen and (max-width: 1440px) {
          //   width: 1200px;
          //   height: 800px;
          // }

          // @media screen and (max-width: 1199px) {
          //   width: 900px;
          //   height: 700px;
          // }

          // @media screen and (max-width: 900px) {
          //   width: 700px;
          //   height: 500px;
          // }

          // @media screen and (max-width: 900px) {
          //   width: 400px;
          //   height: 200px;
          // }

          // @media screen and (max-width: 450px) {
          //   width: 300px;
          //   height: 140px;
          // }
        }
      }
    }
  }

  .joinwaitlist-home-page {
    .joinwaitlist-component {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 1279px;
      height: 302px;
      background-color: #7880cb;
      border-radius: 30px;
      background-image: url("../images/Home/joincomponent/bg.png");
      /* Your background image */
      background-position: right center;
      /* Position the image to the right center */
      background-repeat: no-repeat;
      /* Ensure the image doesn't repeat */
      background-size: contain;

      @media screen and (max-width: 750px) {
        width: 100%;
        font-size: 44px;
        line-height: 54px;
      }

      /* Adjust the size of the image to fit the element */
      h1 {
        width: 689px;
        height: 85.16px;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 77px;
        text-transform: capitalize;
        color: #fafafa;

        @media screen and (max-width: 750px) {
          width: 100%;
          font-size: 44px;
          line-height: 54px;
        }

        @media screen and (max-width: 400px) {
          width: 100%;
          height: auto;
          font-size: 24px;
          line-height: 34px;
        }
      }

      h5 {
        width: 574px;
        height: 34.28px;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 31px;
        text-transform: capitalize;
        color: #fafafa;

        @media screen and (max-width: 750px) {
          font-size: 16px;
          line-height: 26px;
        }

        @media screen and (max-width: 750px) {
          width: 100%;
          line-height: 54px;
        }

        @media screen and (max-width: 400px) {
          margin-top: 10px !important;
          width: 100%;
          font-size: 14px;
          line-height: 34px;
        }
      }

      .email {
        width: 580px;
        height: 66.36px;
        border-radius: 50px;
        position: relative;
        display: flex;
        align-items: center;

        @media screen and (max-width: 750px) {
          width: 450px;
        }

        @media screen and (max-width: 750px) {
          width: 100%;
        }

        @media screen and (max-width: 400px) {
          width: 300px;
        }

        .input-wrapper {
          position: relative;
          width: 100%;

          .name {
            /* Rectangle 3 */
            box-sizing: border-box;
            width: 100%;
            height: 66.36px;
            background: white;
            border: none;
            font-family: "Lato";
            border-radius: 50px;
            color: black;
            font-size: 14px;
            font-weight: 600;
          }

          button {
            width: 174px;
            height: 49px;

            @media screen and (max-width: 750px) {
              width: 100px;
            }
          }

          .email-button {
            gap: 10px;
            position: absolute;
            right: 12px;
            top: 32px;
            transform: translateY(-50%);
            background: #97a1ff;
            border-radius: 50px;
            border: none;
            font-family: "Gilroy";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #ffffff;
            flex: none;
            order: 0;
            flex-grow: 0;

            @media screen and (max-width: 750px) {
              font-size: 13px;
            }

            @media screen and (max-width: 750px) {
              display: none;
            }
          }

          .email-button:hover {
            background-color: #0056b3;
            /* Darker shade for hover effect */
          }
        }

        // .input-wrapper input {
        //     width: calc(100% - 50px);
        //     /* Adjust width considering the button width */
        //     padding-right: 50px;
        //     /* Adjust padding considering the button width */
        // }
      }

      .email-button-mobile {
        margin-top: 10px;
        gap: 10px;
        transform: translateY(-50%);
        background: #97a1ff;
        border-radius: 50px;
        border: none;
        font-family: "Gilroy";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
        flex: none;
        order: 0;
        flex-grow: 0;
      }
    }
  }

  .create-post-application {
    background-image: url("../images/Home/createpost/bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 50px 0px;
    .create-post-feature-component {
      .content {
        h1 {
          font-family: "Gilroy-ExtraBold" !important;
          font-style: normal;
          font-weight: bold;
          font-size: 55px;
          line-height: 65px;
          /* or 113% */
          color: #000000;

          span {
            font-weight: 300;
            font-size: 55px;
            line-height: 65px;
            /* or 113% */
            display: flex;
            align-items: center;
            color: #313131;
          }
        }
        p {
          font-family: "Gilroy-Regular";
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
          /* or 168% */
          color: #000000;
          opacity: 0.5;
        }
        .store_image {
          img {
            width: 250px;
            height: 250px;
          }
        }
      }
      .add-image-column {
        img {
          width: 531.89px;
          height: 618.13px;
        }
      }
    }
  }
  .my-profile-image {
    background-image: url("../images/Home/Myprofile/bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 50px 0px;
  }
}

@media screen and (max-width: 991px) {
  .home-page .add-header .add-header-component .head-data {
    margin-bottom: 20px;
  }

  .application-feature .application_feature_component .headers {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .application-feature .application_feature_component .headers .title {
    font-weight: bold;
    font-size: 40px !important;
    font-family: "Gilroy-ExtraBold" !important;
    text-transform: capitalize;
    color: black;
    user-select: none;
  }

  .application-feature .application_feature_component .headers .title_2 {
    font-size: 30px !important;
    text-transform: capitalize;
    color: black;
    user-select: none;
  }

  // .application-feature .application_feature_component .mobile_section {
  //   gap: 40px;
  //   padding-inline: 20px;
  // }
}

.user-flow-info {
  .home-slider-componenet {
    .top-text {
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      h1 {
        font-family: "Gilroy-Black";
        font-style: normal;
        font-weight: 600;
        font-size: 70px;
        line-height: 79px;
        /* identical to box height, or 113% */
        color: #000000;
        span {
          font-family: "Gilroy-Light";
          font-style: normal;
          font-weight: 300;
          font-size: 70px;
          line-height: 79px;
          /* identical to box height, or 113% */
          color: #000000;
        }
      }
    }
    .slider-component {
      *,
      *::before,
      *::after {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        list-style-type: none;
      }

      :root {
        --primary: #6a59ff;
        --white: #ffffff;
        --bg: #f5f5f5;
      }

      html {
        font-size: 62.5%;
        scroll-behavior: smooth;
      }

      @media (min-width: 1440px) {
        html {
          zoom: 1.5;
        }
      }

      @media (min-width: 2560px) {
        html {
          zoom: 1.7;
        }
      }

      @media (min-width: 3860px) {
        html {
          zoom: 2.5;
        }
      }

      ::-webkit-scrollbar {
        width: 1.3rem;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 1rem;
        background: #797979;
        transition: all 0.5s ease-in-out;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #222224;
      }

      ::-webkit-scrollbar-track {
        background: #f9f9f9;
      }

      body {
        font-size: 1.6rem;
        background: var(--bg);
      }

      .container {
        max-width: 124rem;
        padding: 4rem 1rem;
        margin: 0 auto;
      }

      .heading {
        padding: 1rem 0;
        font-size: 3.5rem;
        text-align: center;
      }

      .swiper_container {
        height: 55rem;
        padding: 2rem 0;
        position: relative;
      }

      .swiper-slide {
        width: 37rem;
        height: 42rem;
        position: relative;
      }

      @media (max-width: 500px) {
        .swiper_container {
          height: 47rem;
        }
        .swiper-slide {
          width: 28rem !important;
          height: 36rem !important;
        }
        .swiper-slide img {
          width: 28rem !important;
          height: 36rem !important;
        }
      }

      .swiper-slide img {
        width: 35rem;
        height: 735px;
        border-radius: 2rem;
        object-fit: contain;
      }

      .swiper-slide-shadow-left,
      .swiper-slide-shadow-right {
        display: none;
      }

      .slider-controler {
        position: relative;
        bottom: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .slider-controler .swiper-button-next {
        left: 58% !important;
        transform: translateX(-58%) !important;
      }

      @media (max-width: 990px) {
        .slider-controler .swiper-button-next {
          left: 70% !important;
          transform: translateX(-70%) !important;
        }
      }

      @media (max-width: 450px) {
        .slider-controler .swiper-button-next {
          left: 80% !important;
          transform: translateX(-80%) !important;
        }
      }

      @media (max-width: 990px) {
        .slider-controler .swiper-button-prev {
          left: 30% !important;
          transform: translateX(-30%) !important;
        }
      }

      @media (max-width: 450px) {
        .slider-controler .swiper-button-prev {
          left: 20% !important;
          transform: translateX(-20%) !important;
        }
      }

      .slider-controler .slider-arrow {
        background: var(--white);
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        left: 42%;
        transform: translateX(-42%);
        filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
      }

      .slider-controler .slider-arrow ion-icon {
        font-size: 2rem;
        color: #222224;
      }

      .slider-controler .slider-arrow::after {
        content: "";
      }

      .swiper-pagination {
        position: relative;
        width: 15rem !important;
        bottom: 1rem;
      }

      .swiper-pagination .swiper-pagination-bullet {
        filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
      }

      .swiper-pagination .swiper-pagination-bullet-active {
        background: var(--primary);
      }
    }
  }
}

.ready-to-get-started {
  .ready-to-get-started-component {
    // @media screen and (max-width: 1200px) {
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: center;
    //   align-items: center;
    // }

    .col-center {
      background-color: #f4f4f4;
      border-radius: 100px 0px 100px 0px;
      padding: 100px;
      @media screen and (max-width: 1200px) {
        // height: 500.69px;
        // width: 600px;
        height: 600px;
      }
      .content {
        h1 {
          font-family: "Gilroy-ExtraBold";
          font-style: normal;
          font-weight: 600;
          font-size: 63.4654px;
          line-height: 65px;
          /* or 102% */
          text-transform: capitalize;
          color: #000000;
        }
        p {
          font-family: "Gilroy-Medium";
          font-style: normal;
          font-weight: 400;
          font-size: 22.918px;
          line-height: 38px;
          /* or 164% */
          text-transform: capitalize;
          color: #000000;
        }
        .store_image {
          background-color: white;
          width: 315.75px;
          border-radius: 50px;
          padding: 20px;

          img {
            object-fit: contain;
            width: 221.48px;
          }
        }
      }
    }
    .blue-bg {
      background-color: #1e9dfb;
      border-radius: 0px 100px 0px 100px;
      // width: 470.7px;
      height: 576.11px;
      // margin-left: 10px;
      @media screen and (max-width: 1250px) {
        margin-left: 0px;
      }
      @media screen and (max-width: 1100px) {
        width: 400px;
        height: 500px;
      }

      img {
        height: 631.69px;
        width: 618.03px;
        margin-top: -90px;
        margin-left: -180px;
        @media screen and (max-width: 1250px) {
          // height: 500.69px;
          width: 600px;
          height: 600px;
          margin-top: -50px;
          margin-left: -70px;
        }
        @media screen and (max-width: 1100px) {
          width: 500px;
          height: 500px;
          margin-top: -50px;
          margin-left: -70px;
        }
        // @media screen and (max-width: 1200px) {
        //   height: 500.69px;
        //   margin-top: -80px;
        //   margin-left: -20px;
        // }
      }
    }
  }
}
