.top-nav-bar {
  
  img {
    width: 90px;
    object-fit: contain;
  }

  .btn-theme-default {
    background: #0A64CA;
    color: white;
    border-radius: 57px;
    font-family: "Gilroy" !important;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    border: none;
    text-transform: capitalize;

  }

  .nav-link {
    font-family: "Gilroy" !important;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 100%;
    color: #000;
  }

  a {
    font-family: "Gilroy" !important;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 100%;
    color: #000;
  }


  .btn-contact-us {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 148px;
    height: 44px;
    margin-top: 12px;
    margin-left: 16px;
  }

  .navbar-expand .navbar-nav {
    margin: 20px 22px;
  }

  .navbar .nav-link.active {
    border-bottom: 2px solid #0A64CA;
    font-family: "Gilroy";
    padding-bottom: 10px;
    color: #0A64CA;
  }

  .navbar .active {
    border-bottom: 2px solid #0A64CA;
    font-family: "Gilroy";
    padding-bottom: 10px;
    color: #0A64CA;
    font-weight: bold !important;
  }
}


@media screen and (max-width: 370px) {
  .top-nav-bar .navbar-brand {
    width: 50%;
  }
}


