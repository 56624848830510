.create-post-feature-component {
  .content {
    h1 {
      @media screen and (max-width: 1200px) {
        font-size: 50px !important;
      }
      @media screen and (max-width: 380px) {
        font-size: 40px !important;
        line-height: 50px !important;
      }

      span {
        @media screen and (max-width: 1200px) {
          font-size: 50px !important;
        }
        @media screen and (max-width: 380px) {
          font-size: 40px !important;
          line-height: 50px !important;
        }
      }
    }
    .store_image {
      @media screen and (max-width: 550px) {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
      }
      img {
        @media screen and (max-width: 550px) {
          width: 250px !important;
          margin-top: 20px;
        }
      }
    }
  }
  .add-image-column {
    text-align: center;
    .add-image {
      @media screen and (max-width: 991px) {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
.ready-to-get-started-component {
  @media screen and (max-width: 580px) {
    width: 450px !important;
  }
  @media screen and (max-width: 450px) {
    width: 80% !important;
    padding: 20px !important;
  }
  .col-center {
    @media screen and (max-width: 450px) {
      padding: 20px !important;
    }
  }
  .content {
    width: 100%;
    h1 {
      @media screen and (max-width: 1200px) {
        font-size: 50px !important;
      }
      @media screen and (max-width: 450px) {
        font-size: 33px !important;
        line-height: 43px !important;
      }
    }
    p {
      @media screen and (max-width: 1200px) {
        // font-size: 18px !important;
      }
      @media screen and (max-width: 450px) {
        margin-top: 30px !important;
        line-height: 28px;
      }
    }
  }
  .store_image {
    @media screen and (max-width: 450px) {
      width: 100% !important;
      margin-top: 40px !important;
    }
    img {
      @media screen and (max-width: 450px) {
        width: 100% !important;
      }
    }
  }
}

.user-flow-info {
  .home-slider-componenet {
    .top-text {
      h1 {
        @media screen and (max-width: 1200px) {
          font-size: 50px !important;
        }
        span {
          @media screen and (max-width: 1200px) {
            font-size: 50px !important;
          }
        }
      }
    }
  }
}

.navbar-toggler {
  @media screen and (max-width: 991px) {
    background-color: $bg-color;
  }
}

.subscribe-input-email {
  @media screen and (max-width: 580px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .subscribe-container {
    @media screen and (max-width: 580px) {
      width: 80% !important;
    }
    .subscribe-button {
      @media screen and (max-width: 450px) {
        width: 100% !important;
      }
    }
  }
}
